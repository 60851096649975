import styled from 'styled-components';
import TextGlobal from '../../../components/Text';
import { variant } from 'styled-system';

export const Text = styled(TextGlobal)`
   ${variant({
  prop: 'styles',
  variants: {
    link: {
      fontFamily: 'manropeRegular',
      fontSize: '18px',
      color: 'black',
      textAlign: 'center',

      '@media (min-width: 0px)': {
        fontSize: '16px',
        textAlign: 'start',
      },

      '@media (min-width: 600px)': {
        fontSize: '12px',
      },

      '@media (min-width: 900px)': {
        fontSize: '15px',
      },

      '@media (min-width: 1200px)': {
        fontSize: '18px',
      },
    },
    linkSelected: {
      fontFamily: 'manropeRegular',
      fontSize: '18px',
      color: 'primary',
      textAlign: 'center',

      '@media (min-width: 0px)': {
        fontSize: '16px',
        textAlign: 'start',
      },

      '@media (min-width: 600px)': {
        fontSize: '12px',
      },

      '@media (min-width: 900px)': {
        fontSize: '15px',
      },

      '@media (min-width: 1200px)': {
        fontSize: '18px',
      },
    },
  },
})}
`;

export const Container = styled.header<{ height?: number }>`
  position: sticky;
  top: 0;
  background-color: white;
  height: ${(props) => props.height}px!important;
  display: flex;
  justify-content: center;
  z-index: 9999999;

  transition-property: all; 
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;

  &.hide {
    top: -${(props) => props.height}px;
  }

  @media (min-width: 900px) {
    &.hide {
      transition-property: none; 
      top: 0px;
    }
  }
`;

export const TopbarContainer = styled.div<{ width: number }>`
  display: flex;
  justify-content: space-between;
  margin-left: ${(props) => Math.round(props.width * 100 / 1536)}px;
  margin-right: ${(props) => Math.round(props.width * 100 / 1536)}px;
  max-width: 1176px;
  width: 100%;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const Separator = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  width: 1px;
  height: 38px;
  user-select: none;
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Link = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0px 16px;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${Text} {
        transition: all 200ms ease;
        color: ${(props) => props.theme.colors.primary};
        user-select: none;
      }
    }
  }
`;

export const SidebarContainer = styled.div<{ isOpen?: boolean, height?: number }>`
  display: flex;
  height: calc(100vh - ${(props) => props.height}px);
  width: 50%;
  position: fixed;
  top: ${(props) => props.height}px!important;
  right: ${(props) => (props.isOpen ? '0' : '-50%')};
  background-color: ${(props) => props.theme.colors.white};
  z-index: 999;
  transition: right 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  overflow-y: auto;
  overflow-x: hidden;
`;

export const SidebarContent = styled.div`
  padding: 20px;
`;

export const BlackBox = styled.div<{ height?: number }>`
  height: 100vh;
  width: 100vw;

  position: fixed;
  top: ${(props) => props.height}px!important;
  right: 0;
  background-color: ${(props) => props.theme.colors.black};
  z-index: 990;
  transition: width 0.3s ease-in-out;
  overflow-y: auto;

  opacity: 0.5;

  overflow: hidden;
`;