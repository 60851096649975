export const getSocialsData = () => {
    return [
        {
            iconSrc: 'images/icons/RRSS-FACEBOOK.svg',
            url: 'https://www.facebook.com/rblrbt',
        },
        {
            iconSrc: 'images/icons/RRSS-X.svg',
            url: 'https://twitter.com/rebelrabbit_pe',
        },
        {
            iconSrc: 'images/icons/RRSS-INSTAGRAM.svg',
            url: 'https://www.instagram.com/rblrbt/',
        },
        {
            iconSrc: 'images/icons/RRSS-LINKEDIN.svg',
            url: 'https://www.linkedin.com/company/rblrbt/',
        },
    ];
};

export const getUsData = () => {
    return [
        {
            iconSrc: 'images/germanSquare.png',
            name: 'Germán Cruz',
            job: 'CTO',
        },
        {
            iconSrc: 'images/richardSquare.png',
            name: 'Richard Da Cruz',
            job: 'CEO',
        },
        {
            iconSrc: 'images/rodolfoSquare.png',
            name: 'Rodolfo Flores',
            job: 'Artista y Animador',
        },
        {
            iconSrc: 'images/raulSquare.png',
            name: 'Raúl Jeri',
            job: 'Programador',
        },
        {
            iconSrc: 'images/yussefSquare.png',
            name: 'Yussef Kahat',
            job: 'Game Designer',
        },
        {
            iconSrc: 'images/francoSquare.png',
            name: 'Franco Vera',
            job: 'CCO',
        },
    ];
};

export const getServicesData = () => {
    return [
        {
            iconSrc: 'images/icons/SERVICE-05.svg',
            text: 'Contenido educativo',
        },
        {
            iconSrc: 'images/icons/SERVICE-01.svg',
            text: 'Impacto Social',
        },
        {
            iconSrc: 'images/icons/SERVICE-02.svg',
            text: 'Capacitaciones',
        },
        {
            iconSrc: 'images/icons/SERVICE-03.svg',
            text: 'Presentaciones interactivas',
        },
        {
            iconSrc: 'images/icons/SERVICE-07.svg',
            text: 'Herramientas de marketing (advergames)',
        },
        {
            iconSrc: 'images/icons/SERVICE-08.svg',
            text: 'Aplicaciones interactivas',
        },
        {
            iconSrc: 'images/icons/SERVICE-06.svg',
            text: 'Activaciones y eventos',
        },
        {
            iconSrc: 'images/icons/SERVICE-04.svg',
            text: 'Entretenimiento y más',
        }
    ];
};

export const getPartnersDetails = () => {
    return [
        "images/icons/PARTNERS-01.svg",
        "images/icons/PARTNERS-02.svg",
        "images/icons/PARTNERS-03.svg",
        "images/icons/PARTNERS-04.svg",
        "images/icons/PARTNERS-05.svg",
        "images/icons/PARTNERS-06.svg",
        "images/icons/PARTNERS-07.svg"
    ];
};

export const getPortfolio = () => {
    return [
        {
            name: 'Wild Wild Space',
            url: 'https://www.youtube.com/embed/FnojM9y3-Jk?si=MU5WOAt-rfDEqGao',
            urlPlaceholder: "images/1.png",
            icon: "images/icons/JUEGOS-WWS.svg",
            desc: "Actualmente en desarollo para la plataforma digital de <b>Steam</b>.",
            etc: "<b>Ganador del 'Concurso Nacional de Desarrollo de Videojuegos – 2022'</b> organizado por el Ministerio de Cultura del Perú.",
        },
        {
            name: 'Duel on Board',
            url: 'https://www.youtube.com/embed/HQquHBQNJTY?si=upqpwhH1l_pz8SNd',
            urlPlaceholder: "images/2.png",
            icon: "images/icons/JUEGOS-DOB.svg",
            desc: "Lanzado para las plataformas digitales de <b>AirConsole, Steam y Nintendo Switch</b> en el 2021.",
            etc: "Nominado como <b>\"Mejor Videojuego Latinoamericano\"</b> en el BIG Festival de Brasil en el 2021, evento de desarrollo de videojuegos más importante de Latinoamérica.",
        },
        {
            name: '64.0',
            url: 'https://www.youtube.com/embed/Yp9AF2rQrqc?si=VhmTmKZh7tPOnRfD',
            urlPlaceholder: "images/3.png",
            icon: "images/icons/JUEGOS-64.svg",
            desc: "Lanzado para las plataformas digitales de <b>Steam y Nintendo Switch</b> en el 2017.",
            etc: "Nominado como <b>\"Mejor Videojuego Latinoamericano\"</b> en el BIG Festival de Brasil en el 2017, evento de desarrollo de videojuegos más importante de Latinoamérica.",
        },
    ];
};

export const getCaptchaKey = () => "6LcuRH8qAAAAAFJ1gryNkesXaQO7ICtW0RPcWU-W";

export const getTransTabData = () => {
    return [
        {
            title: 'TRANSFORMANDO LOS VIDEOJUEGOS',
            desc: [
                "Un videojuego es una experiencia interactiva diseñada para brindar <b>entretenimiento</b>  y <b>desafíos</b>  a los usuarios en un mundo virtual.",
                "Sin embargo, nosotros transformamos este concepto de manera diferente al utilizar los videojuegos como <b>herramientas digitales versátiles</b> en situaciones del mundo real, abordando y resolviendo problemas cotidianos en diversas industrias y mercados.",
                "<b>Ventajas del uso de videojuegos como herramientas:</b>"
            ],
            tabs: [
                {
                    id: 0,
                    name: 'Digitalidad',
                    icon: 'images/icons/ICON-01.svg',
                    desc: "Se desarrollan y presentan en medios electrónicos como <b>tabletas, smartphones, computadoras</b> y otros dispositivos digitales, adaptándose a las preferencias de los usuarios en diversas plataformas tecnológicas.",
                },
                {
                    id: 1,
                    name: 'Diversión',
                    icon: "images/icons/ICON-05.svg",
                    desc: "Brindan una experiencia <b>innovadora</b> que, con desafíos creativos y elementos de <b>gamificación</b>, aborda de manera entretenida problemas y situaciones del mundo real.",
                },
                {
                    id: 2,
                    name: 'Data',
                    icon: "images/icons/ICON-02.svg",
                    desc: "Al ser <b>aplicaciones</b>, estos generan datos valiosos, como análisis de rendimiento, KPIs y bases de datos de usuarios, que permiten evaluar el progreso, el éxito de las iniciativas y el comportamiento de los usuarios, informando así estrategias empresariales más efectivas y personalizadas.",
                },
                {
                    id: 3,
                    name: 'Interactivo y Dinámico',
                    icon: "images/icons/ICON-03.svg",
                    desc: "La distinción del videojuego respecto a otros medios de entretenimiento, como películas o libros, radica en su <b>naturaleza interactiva</b>, donde los usuarios desempeñan un papel activo, fomentando la atención, retención y compromiso a lo largo de toda la experiencia.",
                },
                {
                    id: 4,
                    name: 'Portabilidad y Accesibilidad',
                    icon: "images/icons/ICON-04.svg",
                    desc: "Empleamos tecnologías de vanguardia para desarrollar videojuegos <b>multiplataforma</b>, asegurando su accesibilidad desde cualquier dispositivo y en cualquier momento.",
                }
            ]
        },
        {
            title: 'TODO PUEDE SER UN VIDEOJUEGO',
            desc: [
                "Algunos ejemplos de videojuegos que ya se van aplicando en distintas industrias:",
                "<b2>Cursos interactivos</b2>, abordan diversas áreas de aprendizaje, desde matemáticas y ciencias hasta habilidades de resolución de problemas.",
                "<b2>Cuentos interactivos</b2>, se utilizan para hacer la lectura más divertida, en las que los jugadores toman decisiones que influyen en la historia, creando una experiencia única.",
                "<b2>Simuladores de entrenamiento</b2>, mejoran las habilidades de los colaboradores dentro de la empresa.",
                "<b2>Marketing</b2> a través de advergames, promocionando productos o servicios de manera innovadora y divertida.",
                "<b2>Enseñanza de idiomas</b2>, facilitando el aprendizaje de idiomas extranjeros a través de la práctica interactiva.",
                "Los videojuegos, versátiles y participativos, destacan como herramientas poderosas para resolver desafíos y lograr objetivos en diversas industrias.",
            ],
            tabs: []

        }
    ];
};