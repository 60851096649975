import React, { useState } from 'react';
import Box from '../../components/Box';
import { Text, ImgContainer, Line, IconLink, NLines } from './styles';
import Iframe from 'react-iframe'
import { Grid, Hidden } from '@mui/material';
import CardGame from '../../components/CardGame';
import CardEmployee from '../../components/CardEmployee';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons"

function Indie(): React.ReactNode {

  const [overFacebook, setOverFacebook] = useState(false);
  const [overTwitter, setOverTwitter] = useState(false);

  var usrlang = navigator.language;

  return (
    <>
      <Box display="flex-column" padding="60px 20% 0 20%">
        <ImgContainer>
          <img src="images/logo.png" alt="logo" height={"322px"} />
        </ImgContainer>
        <Text styles='title'>rebel rabbit</Text>
        <NLines>
          <Text styles='desc'>
            {usrlang.includes("es")
              ? "Un estudio de videojuegos independiente que crea juegos de acción con pixel art"
              : "an independent video game studio making pixel art action games"}
          </Text>
        </NLines>
      </Box>

      <Line />

      <Box display="flex" justifyContent="center">
        <Iframe url="https://www.youtube.com/embed/2yu2LX0mwdc"
          width="80%"
          height="684px"
          frameBorder={0}
          id=""
          className=""
          display="block"
          position="relative" />
      </Box>

      <Line />

      <Box display="flex-column" justifyContent="center" alignItems="center" padding="0 10% 0 10%">
        <Text styles='title'>
          {usrlang.includes("es")
            ? "nuestros juegos"
            : "our games"}
        </Text>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <CardGame image="images/banner_wws.png" link="https://store.steampowered.com/app/2341430/Wild_Wild_Space/" />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <CardGame image="images/banner_su.png" link="https://store.steampowered.com/app/1986910/SU_the_Son_of_Gaia/" />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <CardGame image="images/banner_dob.png" link="https://store.steampowered.com/app/612040/Duel_on_Board/" />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <CardGame image="images/banner_64.png" link="https://store.steampowered.com/app/578850/640/" />
          </Grid>
        </Grid>
      </Box >

      <Line />

      <Box display="flex-column" justifyContent="center" padding="0 10% 0 10%">
        <Text styles='title'>
          {usrlang.includes("es")
            ? "equipo"
            : "our team"}
        </Text>

        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} md={6} lg={4}>
            <CardEmployee image="images/german.png" name="Germán"
              job={usrlang.includes("es")
                ? "dirección de juego"
                : "game direction"} />
          </Grid>
          <Hidden lgDown>
            <Grid item xs={12} md={6} lg={4}>
              <CardEmployee image="images/rodolfo.png" name="Rudy"
                job={usrlang.includes("es")
                  ? "pixel art + animación"
                  : "pixel art + animation"} />
            </Grid>
          </Hidden>
          <Hidden lgUp>
            <Grid item xs={12} md={6} lg={4}>
              <CardEmployee image="images/rodolfo2.png" name="Rudy2"
                job={usrlang.includes("es")
                  ? "pixel art + animación"
                  : "pixel art + animation"} />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={6} lg={4}>
            <CardEmployee image="images/richard.png" name="Richard"
              job={usrlang.includes("es")
                ? "desarrollo de negocios"
                : "business development"} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <CardEmployee image="images/raul.png" name="Raúl"
              job={usrlang.includes("es")
                ? "programación"
                : "programming"} />
          </Grid>
          <Hidden lgDown>
            <Grid item xs={12} md={6} lg={4}>
              <CardEmployee image="images/yussef.png" name="Yussef"
                job={usrlang.includes("es")
                  ? "diseño de juegos"
                  : "game design"} />
            </Grid>
          </Hidden>
          <Hidden lgUp>
            <Grid item xs={12} md={6} lg={4}>
              <CardEmployee image="images/yussef2.png" name="Yussef2"
                job={usrlang.includes("es")
                  ? "diseño de juegos"
                  : "game design"} />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={6} lg={4}>
            <CardEmployee image="images/franco.png" name="Franco"
              job={usrlang.includes("es")
                ? "marketing"
                : "marketing"} />
          </Grid>
        </Grid>
      </Box>

      <Line />

      <Box display="flex-column" justifyContent="center" alignItems="center" paddingBottom="40px">
        <Box display="flex" alignItems="center" justifyContent="center">
          <IconLink href="https://www.facebook.com/rblrbt">
            <FontAwesomeIcon
              icon={faFacebook}
              style={{ color: !overFacebook ? "#AAAAAA" : '#FFFFFF', }}
              size='2xl'
              onMouseOver={() => setOverFacebook(true)}
              onMouseOut={() => setOverFacebook(false)} />
          </IconLink>

          <IconLink href="https://twitter.com/rebelrabbit_pe">
            <FontAwesomeIcon
              icon={faTwitter}
              style={{ color: !overTwitter ? "#AAAAAA" : '#FFFFFF', }}
              size='2xl'
              onMouseOver={() => setOverTwitter(true)}
              onMouseOut={() => setOverTwitter(false)} />
          </IconLink>
        </Box>

        <Text styles='footer'>
          {usrlang.includes("es")
            ? "© rebel rabbit. Todos los derechos reservados."
            : "© rebel rabbit. All rights reserved."}
        </Text>
      </Box>
    </ >
  );
}

export default Indie;
